<template>
  <div id="app">
    <!-- 一级路由规则对象组件的挂载点 -->
    <router-view></router-view>
  </div>
</template>
<script>
import 'animate.css/animate.min.css';

export default {
  created() { },
  methods: {}
}
</script>

<script >
// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);

    super(callback);
  }
};
</script>

<style lang="less">
@media screen and (max-width: 1300px) {
  .index_nav {
    >ul {
      width: 80% !important;

      // background-color: red;
      .userInfo {
        flex: 4 !important;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .el-calendar__header {
    padding: 12px 10px !important;
  }

  .el-calendar__body {
    padding: 0;
  }

  .about {
    width: 90% !important;
  }
}

@media screen and (max-width: 1100px) {
  .index_nav {
    >ul {
      width: 90% !important;

      // background-color: green;
      .userInfo {
        flex: 3 !important;
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .index_nav {
    >ul {
      width: 100% !important;

      .el-icon-setting {
        margin-right: 20px;
      }

      // background-color: green;
      .logo {
        margin-right: 0 !important;
      }

      li {
        font-size: 15px !important;
      }
    }
  }

  .footer {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .left {
    .el-image {
      margin: 0 auto;
      display: block;
    }
  }

  .el-main {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) {
  .el-main {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  .index_nav {
    >ul {
      .logo {
        display: none;
      }

      li {
        display: none;
      }

      .menu {
        display: block !important;
      }
    }
  }

  .list {
    width: 90% !important;
  }

  .toolList {
    width: 90% !important;

    // .el-row{
    //   width: auto !important;
    // }
    .right {
      .el-image {
        width: 50px !important;
        height: 50px !important;
      }
    }

    .box_item {
      padding: 0 10px !important;
    }
  }

  .detail {
    padding: 0 20px;
  }

  .about {
    width: 100% !important;
  }
}

@media screen and (max-width: 420px) {}</style>
