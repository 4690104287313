<template>
  <div class="index_nav" :style="{ backgroundColor: bgcolor }">
    <ul class="h-box ul center">
      <div class="logo h-box" @click="toIndex">
        <!-- <div class="img">
            <img src="@/assets/images/logo.png" alt="" class="avatar" />
          </div> -->
        <div class="title">{{ $blogName }}</div>
      </div>
      <li @click="toHome" :class="nowRoute === '/home' ? 'on' : ''">
        <i class="el-icon-s-home"></i>
        首页
      </li>
      <li @click="toType" :class="nowRoute === '/articleType' ? 'on' : ''">
        <i class="el-icon-menu"></i>
        分类
      </li>
      <li @click="toTool" :class="nowRoute === '/tool' ? 'on' : ''">
        <i class="el-icon-s-tools"></i>
        工具
      </li>
      <li @click="toAbout" :class="nowRoute === '/about' ? 'on' : ''">
        <i class="el-icon-user"></i>
        关于
      </li>
      <div class="menu" @click="showMenu">
        <el-image :src="menu" fit="fill" style=" flex-shrink:0;width: 30px; height: 30px;"></el-image>
      </div>
      <div class="userInfo h-box">
        <el-input v-model.trim="searchValue" placeholder="请输入搜索内容" @change="getSearch">
          <!-- <el-button slot="append" icon="el-icon-search" @click="getSearch"></el-button> -->
        </el-input>
        <el-image :src="userImg" fit="cover" style=" flex-shrink:0;width: 50px; height: 50px;border-radius: 50%;"></el-image>
      </div>
      <!-- <div style="width: 10px; height: 10px;align-self: flex-start;cursor: default;" @dblclick="toLogin"></div> -->
      <i class="el-icon-setting" @click="toLogin" style="color: #cccccc; font-size: 25px;margin-left: 20px;"></i>
    </ul>
    <!-- 隐藏菜单 -->
    <el-dialog :visible.sync="dialogVisible" :modal="false" :fullscreen="true" width="100%">
      <div slot="title" @click="toIndex">{{ $blogName }}</div>
      <div class="nav">
        <ul>
          <li @click="toHome" :class="nowRoute === '/home' ? 'on' : ''">
            <i class="el-icon-s-home"></i>
            首页
          </li>
          <li @click="toType" :class="nowRoute === '/articleType' ? 'on' : ''">
            <i class="el-icon-menu"></i>
            分类
          </li>
          <li @click="toTool" :class="nowRoute === '/tool' ? 'on' : ''">
            <i class="el-icon-s-tools"></i>
            工具
          </li>
          <li @click="toAbout" :class="nowRoute === '/about' ? 'on' : ''">
            <i class="el-icon-user"></i>
            关于
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userimg from '@/assets/images/userimg.jpg'
import menu from '@/assets/images/menu.png'

export default {
  props: {
    bgcolor: {
      type: String
    },
    position: {
      type: String,
      default: 'fixed'
    }
  },
  data() {
    return {
      searchValue: '',
      nowRoute: '',
      userImg: userimg,
      menu: menu,
      dialogVisible: false
    }
  },
  mounted() {
    //当前路由
    this.nowRoute = this.$route.path
    console.log(this.nowRoute, this.$route.path == '/home')
  },
  methods: {
    toLogin() {
      // this.$router.push({ path: '/login' })
      const routeUrl = this.$router.resolve({
        path: '/login'
      })
      window.open(routeUrl.href, '_blank')
    },
    toIndex() {
      if (this.nowRoute == '/index') {
        this.dialogVisible = false
        return
      }
      this.$router.push({ name: 'index' })
    },
    toHome() {
      if (this.nowRoute == '/home') {
        this.dialogVisible = false
        return
      }
      this.$router.push({ name: 'home' })
    },
    toType() {
      if (this.nowRoute == '/articleType') {
        this.dialogVisible = false
        return
      }
      this.$router.push({ name: 'articleType' })
    },
    toTool() {
      if (this.nowRoute == '/tool') {
        this.dialogVisible = false
        return
      }
      this.$router.push({ name: 'tool' })
    },
    toAbout() {
      if (this.nowRoute == '/about') {
        this.dialogVisible = false
        return
      }
      this.$router.push({ name: 'about' })
    },
    async getSearch() {
      if (!this.searchValue) {
        return
      }
      if (this.nowRoute === '/search') {
        // alert('发送事件')
        this.$emit('aaaa', this.searchValue)
      } else {
        // alert('路由')
        this.$router.push({ name: 'search', query: { searchValue: this.searchValue } })
      }
    },
    showMenu() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.index_nav {
  width: 100%;
  height: 70px;
  // position: fixed;
  // top: 0;
  // z-index: 99;
  // background-color: red;

  .ul {
    height: 100%;
    .menu {
      margin-left: 20px;
      display: none;
    }
    .logo {
      flex: 2;
      height: 100%;
      justify-content: space-evenly;
      cursor: pointer;
      margin-right: 20px;

      .img {
        width: 50px;
        height: 50px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .title {
        color: #679bd7;
        font-size: 28px;
        font-weight: bold;
      }
    }

    li {
      flex: 1;
      color: #898989;
      width: 100px;
      height: 100%;
      // background-color: pink;
      line-height: 70px;
      text-align: center;
      font-size: 16px;
    }

    li:hover {
      background-color: #ccc;
      color: #000;
      transition: all 1s;
      cursor: pointer;
    }

    .userInfo {
      justify-content: flex-end;
      height: 100%;

      .el-input {
        width: 150px;
        margin-right: 20px;
      }

      flex: 5;
    }

    .on {
      background-color: #ccc;
      color: #000;
      transition: all 1s;
    }
  }
  .el-dialog__wrapper {
    // right: auto;
    // width: 65%;
    .el-dialog .is-fullscreen {
      // width: 65% !important;
    }
    /deep/.el-dialog__body {
      padding: 10px 20px !important;
    }
    .nav {
      // background-color: red;
      width: 100%;
      li {
        height: 50px !important;
        line-height: 50px;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
</style>