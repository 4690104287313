// 具体的接口请求方法
// 引入自定义axios函数
import request from '@/utils/request'
// import store from '@/store'

// 注册
export const registerAPI = ({ username, password, rePassword }) => {
  console.log(username, password)
  return request({
    // url: '/api/reg',
    url: '/api/register',
    method: 'POST',
    data: {
      username,
      password: rePassword
      // password:,
      // repassword: rePassword
    }
  })
}
/**
 *登录接口
 * @param {string} username -账号
 * @param {string} password -密码
 * @returns
 */
export const loginAPI = ({ username, password }) => {
  console.log(username, password)
  return request({
    // url: '/api/reg',
    url: '/api/login',
    method: 'POST',
    data: {
      username,
      password: password
    }
  })
}
// 获取用户信息
export const getUserInfoAPI = () => {
  return request({
    // url: '/api/reg',
    url: '/my/userinfo',
    method: 'GET'
    // 传参给后台：params(查询字符串query路径中),data(请求头body)，headers(请求头)
    // headers: {
    //! 这里需要拿到token token在vuex 和 local storage中 现在取vuex中的
    // this.$store.state.token 这里的this不是组件实例对象，不能使用该方法
    // 解决方案 导入vuex import 'store' from '@/store'
    // Authorization: store.state.token
    // }
  })
}

/**
 * 更新用户信息
 * @param {string}
 */
// eslint-disable-next-line camelcase
export const updateUserInfoAPI = ({ id, username, nickname, email, user_pic }) => {
  return request({
    url: '/my/updateUserInfo',
    method: 'POST',
    data: {
      id,
      username,
      nickname,
      email,
      user_pic
    }
  })
}

/**
 * 更新用户头像
 */
export const updateAvatarAPI = ({ avatar }) => {
  return request({
    url: '/my/update/avatar',
    method: 'POST',
    data: {
      avatar// 头像base64字符串
    }
  })
}
/**
 * 修改密码接口
 */
export const updatePwdAPI = ({ oldPwd, newPwd }) => {
  return request({
    url: '/my/updatePwd',
    method: 'POST',
    data: {
      oldPwd,
      newPwd
    }
  })
}
/**
 * 获取文章类型总数
 */
export const getArticleNumApi=()=>{
  return request({
    url: '/my/article/catesNum',
    method: 'get'
  })
}

/**
 * 获取文章分类列表
 */
export const getArticleListAPI = () => {
  return request({
    url: '/my/article/cates',
    method: 'get'
  })
}

/**
 * 添加文章分类
 */
// eslint-disable-next-line camelcase
export const addCatesAPI = (data) => {
  return request({
    url: '/my/article/addCates',
    method: 'post',
    data
  })
}

/**
 * 修改文章分类
 */
export const updateCateAPI = (obj) => {
  return request({
    url: '/my/article/updatecate',
    method: 'post',
    data: obj
  })
}

/**
 * 删除文章分类
 */
export const deleteCateAPI = (
  Id
) => {
  return request({
    url: `/my/article/deletecate/${Id}`,
    method: 'delete'
    // params: {
    //   Id
    // }
  })
}

/**
 * 添加文章
 * @param {*} fd FormData 表单数据对象
 */
export const addArticleAPI = (
  fd
) => {
  fd.forEach((key, val) => {
    console.log('key %s: value %s', key, val, typeof val)
  })
  return request({
    url: '/my/article/add',
    method: 'post',
    data: fd
  })
}

/**
 * 获取文章列表
 * @param {*} param0 { pagenum: 当前页码数, pagesize: 当前页条数, cate_id: 文章分类id, state: 文章状态 }
 * @returns Promise对象
 */
export const getArticleListsAPI = ({ pagenum, pagesize, cateId, state }) => {
  return request({
    url: '/my/article/list',
    params: {
      pagenum,
      pagesize,
      cate_id: cateId,
      state
    }
  })
}

/**
 * 获取-文章详情
 * @param {*} id 文章id
 * @returns Promise对象
 */
export const getArticleDetailAPI = (id) => {
  return request({
    url: `/my/article/getArticle/${id}`
  })
}

/** 删除文章
 * @param id 文章id
 */
export const deleteArticleAPI = (id) => {
  return request({
    url: `/my/article/delete/${id}`
  })
}

/**
 * 修改文章
 * @param {*} fd FormData 表单数据对象
 */
export const updateArticleAPI = (
  fd
) => {
  fd.forEach((key, val) => {
    console.log('key %s: value %s', key, val)
  })
  return request({
    url: '/my/article/update',
    method: 'post',
    data: fd
  })
}
/**
 *获取当天发布文章数
 * 
 */
export const todayEssayNumApi = () => {
  return request({
    url: '/my/article/todayEssayNum',
    method: 'get'
  })
}
/**
 *获取文章阅读总数
 * 
 */
 export const getReadingNumApi = () => {
  return request({
    url: '/my/article/readingNum',
    method: 'get'
  })
}
/**
 *获取工具类型
 * 
 */
 export const getToolListApi = () => {
  return request({
    url: '/my/toolType/Tools',
    method: 'get'
  })
}
/**
 *添加工具类型
 * @param{tool_title} 标题名
 */
 export const AddToolApi = (title) => {
  return request({
    url: '/my/toolType/addTool',
    method: 'post',
    data:title
  })
}
/**
 *删除工具类型
 * @param{tool_title} 标题名
 */
 export const deleteToolApi = (tool_id) => {
  return request({
    url: `/my/toolType/deleteTool/${tool_id}`,
    method: 'delete'
  })
}
/**
 *修改工具类型
 * @param{data} 对象
 */
 export const updateToolApi = (data) => {
  return request({
    url: `/my/toolType/updateTool`,
    method: 'post',
    data
  })
}

/**
 *获取工具列表
 * @param{data} 对象
 */
 export const getToolApi = (tool_id) => {
  return request({
    url: `/my/toolList/list`,
    method: 'get',
    params:{
      tool_id
    }
  })
}
/**
 *添加工具接口
 * @param{data} 对象
 */
 export const addToolApi = (data) => {
  return request({
    url: `/my/toolList/addTool`,
    method: 'post',
    data
  })
}
/**
 *删除工具接口
 * @param{data} 对象
 */
 export const deleteTool = (id) => {
  return request({
    url: `/my/toolList/deleteTool/${id}`,
    method: 'delete'
  })
}
/**
 *修改工具接口
 * @param{data} 对象
 */
 export const updateTool = (data) => {
  return request({
    url: `/my/toolList/updateTool`,
    method: 'post',
    data
  })
}

// 上传文件接口
export const uploadFile = (fd) => {
  return request({
    url: `/my/upload`,
    method: 'POST',
    data: fd
  })
}

//获取轮播图列表
export const getLunBoListAPI=()=>{
  return request({
    url: '/my/getLunBoList',
    method: 'get'
  })
}
//添加轮播图
export const addLunBoApi=(data)=>{
  return request({
    url: '/my/addLunBo',
    method: 'post',
    data
  })
}
//更新轮播图状态
export const updateLunBoStateApi=(data)=>{
  return request({
    url: '/my/updateLunBoState',
    method: 'put',
    data
  })
}

//删除轮播图
export const deleteLunBo=(id,imgUrl)=>{
  return request({
    url: '/my/deleteLunBo',
    method: 'delete',
    params:{
      id,imgUrl
    }
  })
}

//修改about
export const handleAboutApi=(data)=>{
  return request({
    url:'/my/handleAbout',
    method:'post',
    data
  })
}

//修改about
export const getHandleAboutApi=()=>{
  return request({
    url:'/my/getHandleAbout',
    method:'get'
  })
}






//获取生活图列表
export const getLifePhotoApi=()=>{
  return request({
    url: '/my/getLifePhoto',
    method: 'get'
  })
}
//添加生活图
export const addLifePhotoApi=(data)=>{
  return request({
    url: '/my/addLifePhoto',
    method: 'post',
    data
  })
}
//更新生活图状态
export const updateLifePhotoApi=(data)=>{
  return request({
    url: '/my/updateLifePhoto',
    method: 'put',
    data
  })
}

//删除生活图
export const deleteLifePhotoApi=(id,imgUrl)=>{
  return request({
    url: '/my/deleteLifePhoto',
    method: 'delete',
    params:{
      id,imgUrl
    }
  })
}
