// 基于axios封装，网络请求的函数
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { message } from 'element-ui'

import { showLoading, hideLoading } from '../tool/config' // 加载动画
// axios.create()创建一个待配置项的自定义axios函数
export let baseURL
if (process.env.NODE_ENV=='development') {
  baseURL = 'http://127.0.0.1:3009' //开发环境
} else if(process.env.NODE_ENV=='production'){
  baseURL = '' //生产环境
}

const instance = axios.create({
  baseURL, // 基地址
  timeout: 4000, // 超时时间
})
// 请求拦截器
// api里每次调用request都会先走这个请求拦截器
instance.interceptors.request.use(
  (config) => {
    showLoading()
    //! config配置对象(要请求后台的参数都在这个对象上面)
    //! 该函数在请求之前会触发一次,这个请求会交到axios的源码内，根据配置项发起请求
    console.log('请求头信息', config.headers)
    // 判断接口是否需要传递token，例如登录注册不需要传递token  有值就传递token
    if (store.state.token) {
      config.headers.Authorization = store.state.token
    }
    return config
  },
  (err) => {
    hideLoading()
    //! 请求前的代码如果发送异常报错，会直接进入这里  相当于catch
    // *返回一个错误状态的promise状态的对象
    // promise
    // 口诀：return 非promise对象值，会做为成功的结果返回给下一个promise对象(axios留下原地)
    // 口诀2：return promise对象,这个promise对象状态，返回给下一个promise对象(状态为失败)
    return Promise.reject(err) // 原地留下一个新的Promise对象(状态为失败)
    // 等同于
    // return new Promise((resolve, reject) => {
    //   reject(error)
    // })
  }
)
// 响应拦截器（监听服务器 响应的信息）token 过期
instance.interceptors.response.use(
  (response) => {
    hideLoading()

    // 响应状态码为2xx或3xx时触发成功的回调，response是“成功的结果”
    // return到axios原地的Promise对象，作为成功的结果
    // 0为成功
    console.log(response)
    if(response.data.status===0){
      // console.log('############',response.data)
      return Promise.resolve(response) 
    }
    else{
      return Promise.reject(response.data.message)
    }
  },
  (error) => {
    hideLoading()
    // 响应状态码为4xx，5xx时触发失败的回调，error是“失败的结果”
    // return到axios原地Promise对象位置，作为失败拒绝的状态(如果请求接口那里使用try+catch或者catch函数可以捕获到传递过去的error变量的值)
    // 错误对象使用console.dir(error)打印，console.log()打印不出来
    console.dir(error)
    if (error.response?.status === 401) {
      // 本次响应是token过期了
      // 清除vuex中的一切，然后返回到登录的页面(被动退出)
      store.commit('updateToken', '')
      store.commit('updateUserInfo', {})
      router.push('/index')
      message.error('用户身份过期')
    }
    return Promise.reject(error.response?.data.message)
  }
)

// 导出axios自定义的函数
export default instance
