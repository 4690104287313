import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate' // 用于数据持久化
import { getUserInfoAPI } from '@/api'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '', // 保存token
    userInfo: {}// 用户信息
  },
  getters: {
    username: (state) => state.userInfo.username, // 用户账号
    nickname: (state) => state.userInfo.nickname, // 用户昵称
    user_pic: (state) => state.userInfo.user_pic// 用户头像
  },
  mutations: {
    // 保存token mutations中的方法第一个值必须是state，
    updateToken (state, newToken) {
      state.token = newToken
      // localStorage.setItem('token', JSON.stringify(state.token))
      // console.log('token', state.token)
    },
    // 更新用户信息
    updateUserInfo (state, info) {
      state.userInfo = info
      console.log('state.userInfo', state.userInfo)
    }
  },
  actions: {
    // 获取用户信息
    async initUserInfo (context, value) {
      try {
        console.log(value)
        const { data: res } = await getUserInfoAPI()
        // console.log('res', res)
        context.commit('updateUserInfo', res.data)
      } catch (error) {
        console.error(error)
      }
    }

  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'store', // key
      paths: ['token', 'userInfo'] // 值
    }) // 注入持久化的插件
  ]
})
