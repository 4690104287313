<template>
  <div>
    <v-md-editor v-model="editorValue" :disabled-menus="[]" :include-level="[1, 2, 3]"  @upload-image="handleUploadImage"
       :mode="IsMode == true ? 'preview' : ''"
      @copy-code-success="handleCopyCodeSuccess" :placeholder="placeholder" height="calc(100vh - 150px)"
      :default-show-toc="true" :toc-nav-position-right="false"></v-md-editor>
  </div>
</template>

<script>
import { uploadFile } from '@/api'
import { baseURL } from '@/utils/request'
export default {
  name: 'mdEditor',
  data() {
    return {
      editorValue: '',
      placeholder: '推荐使用markdown语法...'
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    editorValue: function (newNum, oldNum) {
      // 修改调用者传入的值
      this.$emit('input', newNum)
    },
    value: {
      handler(newV) {
        this.editorValue = newV
      },
      immediate: true
    }
  },
  // computed: {
  //   editorValue: {
  //     get() {
  //       return this.value
  //     },
  //     set(value1) {
  //       this.value = value1
  //     }

  //   }
  // },
  props: {
    // 接收值父组件传递值
    value: {
      type: String,
      default: ''
    },
    IsMode: {
      type: Boolean,
      default: false
    }

  },
  methods: {
    // v-md-editor 文件上传
    async handleUploadImage(event, insertImage, files) {
      event.preventDefault()
      const file = files[0]
      const fd = new FormData()
      fd.append('file', file)
      try {
        const res = await uploadFile(fd)
        console.log('返回值', res, baseURL)
        insertImage({
          url: this.$baseURL + res.data.path,
          desc: res.path
        });
      } catch (error) {
        console.error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleCopyCodeSuccess(code) {

      this.$message.success('复制成功')
    }
  },
}
</script>

<style></style>
