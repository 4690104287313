import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/global.less' // 全局初始化样式
import '@/elementUI/index'

import moment from 'moment'
import { baseURL } from '@/utils/request' // 引入根路径
import { uploadFile } from '@/api'
/* v-md-editor 编辑器 start  */
/* 1、v-md-editor 基础引用  */
import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'


/* 3、v-md-editor 二次封装  */
import mdEditor from '@/components/v-md-editor/index'
/* v-md-editor 编辑器 end  */

import myPagination from '@/components/myPagination/myPagination'
import myNav from '@/components/myNav/myNav.vue'
/* 通过按钮快速复制代码块 */
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'

//代码块高亮
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index'
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css'
//代码块显示行数
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index'
import '../public/css.css' //使用全局样式

VueMarkdownEditor.use(createCopyCodePlugin())
VueMarkdownEditor.use(createLineNumbertPlugin())
VueMarkdownEditor.use(createHighlightLinesPlugin())

Vue.prototype.$formatDate = (dateObj) => {
  return moment(dateObj).format('YYYY-MM-DD HH:mm:ss')
}
Vue.prototype.$moment = moment
Vue.prototype.$baseURL = baseURL
Vue.prototype.$uploadFile = uploadFile // 上传接口
Vue.prototype.$blogName = 'XXBlog' //博客名字
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
})

Vue.use(VueMarkdownEditor)


Vue.component('MdEditor', mdEditor)
Vue.component('myPagination', myPagination)
Vue.component('myNav', myNav)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
