<template>
  <div>
    <el-container>
      <el-header :style="{ backgroundColor: bgcolor,position:position }">
        <myNav @aaaa="v=>$emit('bbbb',v)"></myNav>
      </el-header>
      <el-main :class="nowRoute!='/index'?'':'mt0'">
        <slot name="main"></slot>
        <!-- <div class="Copyright" v-if="nowRoute!='/index'">Copyright ©2024 - 西西 | <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2024074404号</a></div> -->
        <div class="Copyright">Copyright ©2024 - 西西 | <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2024074404号</a></div>
        
      </el-main>
      <el-footer v-if="showFooter">
        <div class="footer center">
          <div class="footer_menu" v-if="scrollWidth<750">
            <ul>
              <li @click="toHome" :class="nowRoute === '/home' ? 'on' : ''">
                <i class="el-icon-s-home"></i>
                首页
              </li>
              <li @click="toType" :class="nowRoute === '/articleType' ? 'on' : ''">
                <i class="el-icon-menu"></i>
                分类
              </li>
              <li @click="toTool" :class="nowRoute === '/tool' ? 'on' : ''">
                <i class="el-icon-s-tools"></i>
                工具
              </li>
              <li @click="toAbout" :class="nowRoute === '/about' ? 'on' : ''">
                <i class="el-icon-user"></i>
                关于
              </li>
            </ul>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  props: {
    bgcolor: {
      type: String,
      default: 'transparent'
    },
    position: {
      type: String,
      default: 'fixed'
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      nowRoute: '',
      showMenu: false,
      scrollWidth: document.body.scrollWidth
    }
  },
  mounted() {
    //当前路由
    this.nowRoute = this.$route.path
    window.onresize = () => {
      this.scrollWidth = document.body.scrollWidth
      this.$emit('scrollWidth', this.scrollWidth)
      // console.log('宽度', this.scrollWidth)
    }
  },
  watch: {
    // scrollWidth: {
    //   handler(newVal, oldVal) {
    //     if (newVal < 750) {
    //       this.showMenu = true
    //     }
    //     console.log('@@@@', this.showMenu,newVal,oldVal)
    //   },
    //   // deep: true,
    //   immediate: true
    // }
  },
  methods: {
    toHome() {
      this.$router.push({ name: 'home' })
    },
    toType() {
      this.$router.push({ name: 'articleType' })
    },
    toTool() {
      this.$router.push({ name: 'tool' })
    },
    toAbout() {
      this.$router.push({ name: 'about' })
    }
  }
}
</script>

<style lang="less" scoped>
.el-header {
  padding: 0;
  height: 70px !important;
  position: fixed;
  z-index: 99;
  width: 100%;
}
.el-main {
  padding: 0;
  margin-top:70px ;

}
.mt0{
  margin-top:0px ;
}
.el-footer {
  padding: 0;
  height: auto !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
}
.footer {
  text-align: center;
  // margin-top: 30px;
  // margin-bottom: 30px;
  /deep/.footer_menu {
    background-color: #000;
    height: 55px;
    ul {
      display: flex;
      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        // margin-top: 9px;
        height: 55px;
      }
      .on {
        background-color: #ccc;
        color: #000;
        transition: all 1s;
      }
    }
  }
}

.Copyright {
  text-align: center;
  color: #000;
  margin-top: 20px;
  font-size: 12px;
  a {
    color: #000;
    font-size: 12px;
  }
}
</style>