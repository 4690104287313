import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    //欢迎页
    path: '/index',
    name: 'index',
    component: () => import('@/views/index'),
    meta: {
      title: '欢迎', // 设置页面标题
    },
  },
  {
    //首页
    path: '/home',
    name: 'home',
    component: () => import('@/views/index/home'),
    meta: {
      title: '首页', // 设置页面标题
    },
  },
  {
    //文章类型页
    path: '/articleType',
    name: 'articleType',
    component: () => import('@/views/index/articleType'),
    meta: {
      title: '文章类型', // 设置页面标题
    },
  },
  {
    //文章列表页
    path: '/essay',
    name: 'essay',
    component: () => import('@/views/index/essay'),
    meta: {
      title: '文章列表', // 设置页面标题
    },
  },
  {
    //搜索页
    path: '/search',
    name: 'search',
    component: () => import('@/views/index/search'),
    meta: {
      title: '搜索', // 设置页面标题
    },
  },
  {
    //文章详情页
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/index/detail'),
    meta: {
      title: '详情', // 设置页面标题
    },
  },
  {
    //工具页
    path: '/tool',
    name: 'tool',
    component: () => import('@/views/index/tool'),
    meta: {
      title: '工具', // 设置页面标题
    },
  },
  {
    //about
    path: '/about',
    name: 'about',
    component: () => import('@/views/index/about'),
    meta: {
      title: '关于', // 设置页面标题
    },
  },
  {
    path: '/layout',
    component: () => import('@/views/layout'),
    redirect: '/layout/home',
    // 默认打开直接看到布局页
    children: [
      {
        path: 'home',
        component: () => import('@/views/home'),
        meta: {
          title: '后台首页', // 设置页面标题
        },
      },
      {
        path: 'user-info',
        component: () => import('@/views/user/userInfo'),
        meta: {
          title: '基本信息', // 设置页面标题
        },
      },
      {
        path: 'userAvatar',
        component: () => import('@/views/user/userAvatar'),
        meta: {
          title: '更换头像', // 设置页面标题
        },
      },
      {
        path: 'userPwd',
        component: () => import('@/views/user/userPwd'),
        meta: {
          title: '重置密码', // 设置页面标题
        },
      },
      {
        path: 'carousel',
        component: () => import('@/views/user/carousel'),
        meta: {
          title: '设置首页轮播图', // 设置页面标题
        },
      },
      {
        path: 'life',
        component: () => import('@/views/user/life'),
        meta: {
          title: '生活趣事', // 设置页面标题
        },
      },
      {
        path: 'art-cate',
        component: () => import('@/views/article/artCate'),
        meta: {
          title: '文章分类', // 设置页面标题
        },
      },
      {
        path: 'art-list',
        component: () => import('@/views/article/artList'),
        meta: {
          title: '文章管理', // 设置页面标题
        },
      },
      //工具
      {
        path: 'tool_type',
        component: () => import('@/views/tool/tool_type'),
        meta: {
          title: '工具分类', // 设置页面标题
        },
      },
      {
        path: 'tool_list',
        component: () => import('@/views/tool/tool_list'),
        meta: {
          title: '工具管理', // 设置页面标题
        },
      },
      {
        path: 'about',
        component: () => import('@/views/about/about'),
        meta: {
          title: 'about', // 设置页面标题
        },
      },
    ],
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    meta: {
      title: '注册', // 设置页面标题
    },
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      title: '登录', // 设置页面标题
    },
  },
  {
    path: '*',
    redirect: '/index',
  },
]

const router = new VueRouter({
  mode:'history',
  routes,
})
// 路由白名单(无需登录可以访问的地址)
const whiteList = [
  '/index',
  '/login',
  '/register',
  '/home',
  '/articleType',
  '/essay',
  '/tool',
  '/about',
  '/detail',
  '/search'
]
// 配置全局路由守卫
// 知识点一：浏览器第一次打开项目页面，会触发一次全局的前置路由守卫函数
// 知识点二：有token证明已经登录，无token表示未登录.
// 知识点三:next()如果强制切换路由地址，会再吃走路由守卫去再次匹配路由表
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '默认标题'; // 设置页面标题
  next();
  // 路由发生变化就会调用
  console.log('已调用全局前置路由守卫')
  const token = store.state.token
  if (token) {
    // 已登录
    // 作用：有token值才会请求用户数据接口
    if (!store.state.userInfo.username) {
      //*   !store.state.userInfo.username有值就不调用(没有用户信息)
      store.dispatch('initUserInfo')
    }
    next()
  } else {
    // 未登录
    // 数组.includes.作用判断值是否出现在数组中出现过，出现过返回true
    if (whiteList.includes(to.path)) {
      // 未登录，该地址存在于白名单，放行(路由全局前置守卫不会在触发了而是匹配路由表，让组件挂载)
      next()
    } else {
      // 强制跳转到登录页面
      next('/index')
    }
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}
export default router
